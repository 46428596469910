import { useTranslation } from 'react-i18next';
import { useState, useEffect, useRef } from 'react';
import ManualSpreadsheet from '../spreadsheet/ManualSpreadsheet';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, updateTicketAmount, addRecipients } from './modalSlice';
import { allocationState } from '../../stores/allocationSlice';
import { userEvent } from '../../utils/analytics';
import { emailValidator } from '../../utils/helpers/validators';
import { v4 as uuid } from 'uuid';
import { ReactComponent as WarningIcon } from '../../assets/icons/ValidationErrorIcon.svg';

const ManualPeopleModal = ({
  setManualTicketRemainingAmount,
  handleModalClose,
  ticketRemainingAmount,
  gamePk,
  setHasBeenEdited,
  modalRef
}) => {
  const [spreadsheetData, setSpreadsheetData] = useState([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const hotTableRef = useRef();
  const [validationActive, setValidationActive] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const allocationData = useSelector(allocationState);

  useEffect(() => {
    //Sets up the initial spreadsheet data
    const formattedData = [];
    const nonVenueFormattedData = [];
    allocationData.forEach((row) => {
      row.forEach((seat) => {
        if (seat.email) {
          return;
        }
        const seatData = [
          seat.location.section.value,
          seat.location.row.value,
          seat.location.seat.value,
          '',
          '',
          seat.ticketId
        ];

        if (seatData[0].includes('Parking')) {
          seatData[0] = 'Parking';
          seatData[1] = '-';
          seatData[2] = '-';
        }
        if (seatData[0].includes('Voucher')) {
          seatData[0] = 'Voucher';
          seatData[1] = '-';
          seatData[2] = '-';
        }
        if (seat.access === 'VENUE') {
          formattedData.push(seatData);
          return;
        }
        nonVenueFormattedData.push(seatData);
      });
    });
    setSpreadsheetData(formattedData.concat(nonVenueFormattedData));
    //Venue tickets need to be displayed before non-venue tickets
  }, [allocationData]);

  useEffect(() => {
    //This adds the tooltip to the first invalid cell after validation
    const hot = hotTableRef.current.hotInstance;
    if (validationActive) {
      const currentSelection = hot.getSelected();
      hot.selectCell(
        currentSelection[0][0],
        currentSelection[0][1],
        currentSelection[0][2],
        currentSelection[0][3],
        true
      );
    }
  }, [validationActive]);

  const addTooltipOnSelect = (row, column, row2, column2) => {
    //Adds a tooltip to a selected cell when validation is active
    const hot = hotTableRef.current.hotInstance;
    const tooltip = document.querySelector('.hot-tooltip');
    tooltip.style.visibility = 'hidden';
    if (validationActive) {
      if (
        row === row2 &&
        column === column2 &&
        //Checks that only one cell is selected
        !(hot.getDataAtCell(row, 3) === '' && hot.getDataAtCell(row, 4) === '')
        //Does not add tooltip if name and email are both blank
      ) {
        const selected = hot.getSelected();
        const TD = hot.getCell(selected[0][0], selected[0][1]);
        const value = hot.getDataAtCell(row, column);
        const modalRect = modalRef.current.getBoundingClientRect();
        if (!value || value === '') {
          tooltip.style.visibility = 'visible';
          tooltip.innerHTML = t('Required_field');
          if (TD) {
            const tdRect = TD.getBoundingClientRect();
            const tooltipRect = tooltip.getBoundingClientRect();
            tooltip.style.top = `${tdRect.top - modalRect.top - 35}px`;
            tooltip.style.left = `${tdRect.x - modalRect.x + (tdRect.width - tooltipRect.width) / 2}px`;
          }
        } else if (column === 4 && !emailValidator.test(value)) {
          //Adds tooltip if the email validation fails
          tooltip.style.visibility = 'visible';
          tooltip.innerHTML = t('Email_invalid');
          if (TD) {
            const tdRect = TD.getBoundingClientRect();
            const tooltipRect = tooltip.getBoundingClientRect();
            tooltip.style.top = `${tdRect.top - modalRect.top - 35}px`;
            tooltip.style.left = `${tdRect.x - modalRect.x + (tdRect.width - tooltipRect.width) / 2}px`;
          }
        }
      }
    }
  };

  const validateSave = () => {
    //Validates all data
    const ht = hotTableRef.current.hotInstance;
    for (let i = 0; i < ht.countRows(); i++) {
      if (ht.getDataAtCell(i, 3) !== '' || ht.getDataAtCell(i, 4) !== '') {
        if (!ht.getDataAtCell(i, 3) || ht.getDataAtCell(i, 3) === '') {
          ht.scrollViewportTo(i, 3);
          ht.selectCell(i, 3, i, 3, true);
          return false;
        }
        if (!ht.getDataAtCell(i, 4) || ht.getDataAtCell(i, 4) === '' || !emailValidator.test(ht.getDataAtCell(i, 4))) {
          ht.scrollViewportTo(i, 4);
          ht.selectCell(i, 4, i, 4, true);
          return false;
        }
      }
    }
    return true;
  };

  const handlePeopleModalSave = (e) => {
    setValidationActive(true);
    e.preventDefault();
    userEvent({
      action: {
        element: {
          index: 2,
          text: 'save and continue to ticket view : manually allocate',
          targetUrl: '/available-tickets'
        }
      },
      event: 'modal-click',
      modal: {
        option: {
          indexTotal: 2
        },
        title: 'add multiple people'
      },
      page: {
        app: {
          name: 'bulk ticket management'
        },
        gamePk: gamePk,
        title: 'group tickets'
      }
    });
    if (validateSave() === false) {
      return;
    }
    setValidationActive(false);
    dispatch(addRecipients(createRecipientsData(spreadsheetData)));
    dispatch(updateTicketAmount(ticketRemainingAmount));
    dispatch(closeModal('spreadsheet'));
    setHasBeenEdited(true);
  };

  const createRecipientsData = (spreadsheetData) => {
    //Takes in spreadsheet data and returns an array of recipients which can be added
    //to the recipients redux store
    let recipientsToAdd = [];
    spreadsheetData.forEach((entry) => {
      if (entry[3] && entry[3] !== '' && entry[4] && entry[4] !== '' && entry[5] && entry[5] !== '') {
        recipientsToAdd.push({
          name: entry[3],
          email: entry[4],
          ticketId: entry[5],
          recipientId: uuid(),
          seatCount: entry[0].includes('Parking') || entry[0].includes('Voucher') ? 0 : 1
        });
      }
    });
    return recipientsToAdd;
  };

  useEffect(() => {
    userEvent({
      event: 'modal-impression',
      modal: {
        option: {
          indexTotal: 1
        },
        title: 'add multiple people'
      },
      page: {
        app: {
          name: 'bulk ticket management'
        },
        gamePk: gamePk,
        title: 'group tickets',
        subPage: {
          title: 'available tickets'
        }
      }
    });
  }, [gamePk]);

  return (
    <>
      <div
        className="add_modal_body spreadsheet"
        tabIndex="0"
        aria-label={t('spreadsheet_desc')}
        data-testid="manualPeopleModal"
      >
        {validationActive ? (
          <div className="people-validation" role="alert">
            <WarningIcon className="warning-icon" alt={t('Validation_Error')} />
            <span>{t('People_Validation_Error')}</span>
          </div>
        ) : null}
        <ManualSpreadsheet
          hotTableRef={hotTableRef}
          validationActive={validationActive}
          initData={spreadsheetData}
          setManualTicketRemainingAmount={setManualTicketRemainingAmount}
          addTooltipOnSelect={addTooltipOnSelect}
          setIsSaveDisabled={setIsSaveDisabled}
          modalRef={modalRef}
        />
      </div>
      <div className="add_modal_footer spreadsheet_footer">
        <button
          className="modal_footer_left_btn"
          id="modal_cancel_btn"
          type="button"
          onClick={(e) => handleModalClose(e, true)}
        >
          {t('Modal_Cancel_Btn')}
        </button>
        <button
          className="modal_footer_right_btn"
          disabled={isSaveDisabled}
          type="submit"
          id="modal_save_btn"
          onClick={handlePeopleModalSave}
        >
          {t('Modal_Save_Btn')}
        </button>
      </div>
    </>
  );
};

export default ManualPeopleModal;
